<template>
  <div v-if="labelShow">
    <div style="display: flex;align-items: center;margin-bottom: 15px;" v-if="!$store.state.isMobile">
      <div>
        <v-btn color="primary" outlined small
               @click="closeLabelDialog"
        ><v-icon>mdi-chevron-left</v-icon> Back to list</v-btn
        >
      </div>
    </div>
    <div style="position: relative;box-shadow: none;" :style="`padding-bottom:${$store.state.isMobile?'60px':'0'}`">
      <div style="display:flex;min-height:800px;" :style="`flex-direction:${$store.state.isMobile?'column':'row'};`">
        <div :style="`padding:${$store.state.isMobile?0:'15px'};width:${$store.state.isMobile?'100%':'50%'};`">
          <div style="margin-bottom:15px;">
            <label style="display:flex;align-items:center;margin-bottom:15px;" class="label">Header text</label>
            <Editor
                v-if="renderComponent"
                :key="`header_text`"
                api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                :init="options"
                v-model="label.header_text"
            >
            </Editor>
          </div>
          <div style="margin-bottom:15px;">
            <label style="display:flex;align-items:center;margin-bottom:15px;" class="label">Bottom text</label>
            <Editor
                v-if="renderComponent"
                :key="`bottom_text`"
                api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                :init="options"
                v-model="label.bottom_text"
            >
            </Editor>
          </div>
          <div
              style="display: flex; align-items: center; margin-bottom:15px"
          >
            <label
                class="label"
                :style="`min-width:160px`" style=" margin-bottom: 0; margin-right: 15px"
            >Background</label
            >
            <v-menu
                top
                nudge-left="16"
                nudge-bottom="98"
                :close-on-content-click="false"
                v-model="show_background"
            >
              <template v-slot:activator="{ on }">
                <div
                    class="custom__menu--color"
                    :style="`background-color:${label.styles.bg}`"
                    v-on="on"
                />
              </template>
              <v-card>
                <v-card-text class="pa-0">
                  <v-color-picker v-model="label.styles.bg" flat />
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
          <div
              style="display: flex; align-items: center; margin-bottom:15px"
          >
            <label
                class="label"
                :style="`min-width:160px`" style=" margin-bottom: 0; margin-right: 15px"
            >Qr color</label
            >
            <v-menu
                top
                nudge-left="16"
                nudge-bottom="98"
                :close-on-content-click="false"
                v-model="show_qr_color"
            >
              <template v-slot:activator="{ on }">
                <div
                    class="custom__menu--color"
                    :style="`background-color:${label.styles.qr.color}`"
                    v-on="on"
                />
              </template>
              <v-card>
                <v-card-text class="pa-0">
                  <v-color-picker v-model="label.styles.qr.color" flat/>
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
          <div
              style="display: flex; align-items: center; margin-bottom:15px"
          >
            <label
                class="label"
                :style="`min-width:160px;`" style=" margin-bottom: 0; margin-right: 15px"
            >Qr background color</label
            >
            <v-menu
                top
                nudge-left="16"
                nudge-bottom="98"
                :close-on-content-click="false"
                v-model="show_qr_background"
            >
              <template v-slot:activator="{ on }">
                <div
                    class="custom__menu--color"
                    :style="`background-color:${label.styles.qr.bg}`"
                    v-on="on"
                />
              </template>
              <v-card>
                <v-card-text class="pa-0">
                  <v-color-picker v-model="label.styles.qr.bg" flat/>
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
          <div
              style="display: flex; align-items: center; margin-bottom: 15px"
          >
            <label class="label" style="width:160px;margin-bottom: 0;margin-right:7px;">
              Qr size <b>{{ label.styles.qr.size }}%</b>
            </label>
            <div class="control" style="width: 75%">
              <div class="slider">
                <v-slider
                    @input="rerenderQr"
                    hide-details
                    :max="256"
                    :min="32"
                    v-model="label.styles.qr.size"
                ></v-slider>
              </div>
            </div>
          </div>
          <div v-if="!$store.state.isMobile" style="display:flex;align-items:center;justify-content:flex-end;">
            <v-btn
                @click="save"
                color="primary"
                depressed
            >
              Save</v-btn
            >
          </div>
        </div>
        <div v-show="!$store.state.isMobile" style="display:flex;flex-direction:column;align-items:center;border-radius:5px;background-color: rgba(0, 125, 181, 0.12);padding-top:40px;" :style="`width:${$store.state.isMobile?'100%':'50%'};`">
            <div style="margin-bottom:15px;display:flex;align-items:center;justify-content: center;" :style="`width:${$store.state.isMobile?'100%':'400px'}`">
              <v-btn color="primary" outlined small @click="download(label)" style="margin-right: 15px;background-color:#fff;">
                <v-icon
                    color="#007db5"
                    style="margin-right: 5px;"
                >mdi-download</v-icon
                >Download
              </v-btn>

              <v-btn color="primary" outlined small @click="print(label)" style="background-color:#fff;">
                <v-icon
                    color="#007db5"
                    style="margin-right: 5px;"
                >mdi-printer</v-icon
                >Print
              </v-btn>
            </div>
          <div :ref="`ref_${label.qr}`" style="margin-bottom: 15px;" :style="`width:${$store.state.isMobile?'90%':'auto'}`">
            <div style="height:564px;border-radius: 16px;" :style="`background-color:${label.styles.bg};width:${$store.state.isMobile?'100%':'400px'}`">
              <div style="height:100%;padding:15px;">
                <div style="margin:0 auto 30px auto;width:100%;">
                  <div v-html="label.header_text"></div>
                </div>
                <div style="width:100%;margin-bottom:30px;">
                  <qr-code
                      id="qr_code"
                      :text="qrLink"
                      :key="label.styles.qr.size+label.styles.qr.color+label.styles.qr.bg"
                      style="margin:0 auto;"
                      :style="`width:${label.styles.qr.size}px;height:${label.styles.qr.size}px;`"
                      :size="label.styles.qr.size"
                      :color="label.styles.qr.color"
                      :bg-color="label.styles.qr.bg"
                      error-level="H"
                  ></qr-code>
                </div>
                <div style="width:100%;">
                  <div v-html="label.bottom_text"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PopupSuccess ref="PopupSuccess" :title="'Success!'" :content="successMessage" />
    <div v-if="$store.state.isMobile" style="position:fixed;bottom:0;left:0;display: flex;background:#fff;width: 100vw;overflow:hidden;padding: 5px;box-shadow: 0 0 0 1px rgba(68,92,116,0.02),0 2px 8px rgba(57,76,96,0.15);">
      <div @click="openMenuBottom(menu)" :style="`color:${menu.active?'#007db5':'#7f8385'};`" style="flex:1;display: flex;flex-direction: column;align-items: center;justify-content: center;" v-for="menu in mobileMenuLabels" :key="menu.name">
        <div><v-icon :color="`${menu.active?'#007db5':'#7f8385'}`">{{menu.icon}}</v-icon></div>
        <div style="font-size:12px;">{{menu.name}}</div>
      </div>
    </div>
    <v-dialog :fullscreen="$store.state.isMobile" v-model="preview_modal" max-width="700" eager @click:outside="closePreviewModal">
      <div style="background: #fff;height: 100%;display: flex;justify-content: center;align-items: center;">
        <v-btn class="popup__close" small icon @click="closePreviewModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div style="width: 100%">
          <div style="display:flex;flex-direction:column;align-items:center;border-radius:5px;background-color: rgba(0, 125, 181, 0.12);padding-top:40px;" :style="`width:${$store.state.isMobile?'100%':'50%'};`">
            <div style="margin-bottom: 15px;" :style="`width:${$store.state.isMobile?'90%':'auto'}`">
              <div style="height:564px;border-radius: 16px;" :style="`background-color:${label.styles.bg};width:${$store.state.isMobile?'100%':'400px'}`">
                <div style="height:100%;padding:15px;">
                  <div style="margin:0 auto 30px auto;width:100%;">
                    <div v-html="label.header_text"></div>
                  </div>
                  <div style="width:100%;margin-bottom:30px;">
                    <qr-code
                        id="qr_code"
                        :text="qrLink"
                        :key="label.styles.qr.size+label.styles.qr.color+label.styles.qr.bg"
                        style="margin:0 auto;"
                        :style="`width:${label.styles.qr.size}px;height:${label.styles.qr.size}px;`"
                        :size="label.styles.qr.size"
                        :color="label.styles.qr.color"
                        :bg-color="label.styles.qr.bg"
                        error-level="H"
                    ></qr-code>
                  </div>
                  <div style="width:100%;">
                    <div v-html="label.bottom_text"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import 'vue2-timepicker/dist/VueTimepicker.css'
import "vue2-datepicker/index.css";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Compressor from "compressorjs";
import Cropper from "cropperjs";
import "../../../node_modules/cropperjs/dist/cropper.min.css";
import {mapActions,mapState} from "vuex";
import LabelService from '@/services/label.service'
import liteLabelsService from "@/services/liteLabels.service";
import HotelService from "@/services/hotel.service";
export default {
  name: "LabelPopup",
  props:['labelShow','label','type','objectItem'],
  data(){
    return{
      preview_modal:false,
      mobileMenuLabels:[
        {
          id:0,
          name:"Preview",
          icon:"mdi-eye",
          link:"",
          active:false
        },
        {
          id:1,
          name:"Save",
          icon:"mdi-application-export",
          link:"",
          active:false
        },
        {
          id:2,
          name:"Download",
          icon:"mdi-download",
          link:"",
          active:false
        },
      ],
      renderQR:false,
      renderComponent:false,
      show_qr_background:false,
      show_qr_color:false,
      show_background:false,
      defaultLabel:{
        header_text:"Welcome",
        qr:null,
        bottom_text:"Scan me to view our app!"
      },
      langShow:false,
      selectedTemplate:null,
      templates:[],
      countries: [
        { value: "AF", label: "Afghanistan" },
        { value: "AX", label: "Åland Islands" },
        { value: "AL", label: "Albania" },
        { value: "DZ", label: "Algeria" },
        { value: "AS", label: "American Samoa" },
        { value: "AD", label: "Andorra" },
        { value: "AO", label: "Angola" },
        { value: "AI", label: "Anguilla" },
        { value: "AQ", label: "Antarctica" },
        { value: "AG", label: "Antigua and Barbuda" },
        { value: "AR", label: "Argentina" },
        { value: "AM", label: "Armenia" },
        { value: "AW", label: "Aruba" },
        { value: "AU", label: "Australia" },
        { value: "AT", label: "Austria" },
        { value: "AZ", label: "Azerbaijan" },
        { value: "BS", label: "Bahamas" },
        { value: "BH", label: "Bahrain" },
        { value: "BD", label: "Bangladesh" },
        { value: "BB", label: "Barbados" },
        { value: "BY", label: "Belarus" },
        { value: "BE", label: "Belgium" },
        { value: "BZ", label: "Belize" },
        { value: "BJ", label: "Benin" },
        { value: "BM", label: "Bermuda" },
        { value: "BT", label: "Bhutan" },
        { value: "BO", label: "Bolivia, Plurinational State of" },
        { value: "BQ", label: "Bonaire, Sint Eustatius and Saba" },
        { value: "BA", label: "Bosnia and Herzegovina" },
        { value: "BW", label: "Botswana" },
        { value: "BV", label: "Bouvet Island" },
        { value: "BR", label: "Brazil" },
        { value: "IO", label: "British Indian Ocean Territory" },
        { value: "BN", label: "Brunei Darussalam" },
        { value: "BG", label: "Bulgaria" },
        { value: "BF", label: "Burkina Faso" },
        { value: "BI", label: "Burundi" },
        { value: "KH", label: "Cambodia" },
        { value: "CM", label: "Cameroon" },
        { value: "CA", label: "Canada" },
        { value: "CV", label: "Cape Verde" },
        { value: "KY", label: "Cayman Islands" },
        { value: "CF", label: "Central African Republic" },
        { value: "TD", label: "Chad" },
        { value: "CL", label: "Chile" },
        { value: "CN", label: "China" },
        { value: "CX", label: "Christmas Island" },
        { value: "CC", label: "Cocos (Keeling) Islands" },
        { value: "CO", label: "Colombia" },
        { value: "KM", label: "Comoros" },
        { value: "CG", label: "Congo" },
        { value: "CD", label: "Congo, the Democratic Republic of the" },
        { value: "CK", label: "Cook Islands" },
        { value: "CR", label: "Costa Rica" },
        { value: "CI", label: "Côte d'Ivoire" },
        { value: "HR", label: "Croatia" },
        { value: "CU", label: "Cuba" },
        { value: "CW", label: "Curaçao" },
        { value: "CY", label: "Cyprus" },
        { value: "CZ", label: "Czech Republic" },
        { value: "DK", label: "Denmark" },
        { value: "DJ", label: "Djibouti" },
        { value: "DM", label: "Dominica" },
        { value: "DO", label: "Dominican Republic" },
        { value: "EC", label: "Ecuador" },
        { value: "EG", label: "Egypt" },
        { value: "SV", label: "El Salvador" },
        { value: "GQ", label: "Equatorial Guinea" },
        { value: "ER", label: "Eritrea" },
        { value: "EE", label: "Estonia" },
        { value: "ET", label: "Ethiopia" },
        { value: "FK", label: "Falkland Islands (Malvinas)" },
        { value: "FO", label: "Faroe Islands" },
        { value: "FJ", label: "Fiji" },
        { value: "FI", label: "Finland" },
        { value: "FR", label: "France" },
        { value: "GF", label: "French Guiana" },
        { value: "PF", label: "French Polynesia" },
        { value: "TF", label: "French Southern Territories" },
        { value: "GA", label: "Gabon" },
        { value: "GM", label: "Gambia" },
        { value: "GE", label: "Georgia" },
        { value: "DE", label: "Germany" },
        { value: "GH", label: "Ghana" },
        { value: "GI", label: "Gibraltar" },
        { value: "GR", label: "Greece" },
        { value: "GL", label: "Greenland" },
        { value: "GD", label: "Grenada" },
        { value: "GP", label: "Guadeloupe" },
        { value: "GU", label: "Guam" },
        { value: "GT", label: "Guatemala" },
        { value: "GG", label: "Guernsey" },
        { value: "GN", label: "Guinea" },
        { value: "GW", label: "Guinea-Bissau" },
        { value: "GY", label: "Guyana" },
        { value: "HT", label: "Haiti" },
        { value: "HM", label: "Heard Island and McDonald Mcdonald Islands" },
        { value: "VA", label: "Holy See (Vatican City State)" },
        { value: "HN", label: "Honduras" },
        { value: "HK", label: "Hong Kong" },
        { value: "HU", label: "Hungary" },
        { value: "IS", label: "Iceland" },
        { value: "IN", label: "India" },
        { value: "ID", label: "Indonesia" },
        { value: "IR", label: "Iran, Islamic Republic of" },
        { value: "IQ", label: "Iraq" },
        { value: "IE", label: "Ireland" },
        { value: "IM", label: "Isle of Man" },
        { value: "IL", label: "Israel" },
        { value: "IT", label: "Italy" },
        { value: "JM", label: "Jamaica" },
        { value: "JP", label: "Japan" },
        { value: "JE", label: "Jersey" },
        { value: "JO", label: "Jordan" },
        { value: "KZ", label: "Kazakhstan" },
        { value: "KE", label: "Kenya" },
        { value: "KI", label: "Kiribati" },
        { value: "KP", label: "Korea, Democratic People's Republic of" },
        { value: "KR", label: "Korea, Republic of" },
        { value: "KW", label: "Kuwait" },
        { value: "KG", label: "Kyrgyzstan" },
        { value: "LA", label: "Lao People's Democratic Republic" },
        { value: "LV", label: "Latvia" },
        { value: "LB", label: "Lebanon" },
        { value: "LS", label: "Lesotho" },
        { value: "LR", label: "Liberia" },
        { value: "LY", label: "Libya" },
        { value: "LI", label: "Liechtenstein" },
        { value: "LT", label: "Lithuania" },
        { value: "LU", label: "Luxembourg" },
        { value: "MO", label: "Macao" },
        { value: "MK", label: "Macedonia, the Former Yugoslav Republic of" },
        { value: "MG", label: "Madagascar" },
        { value: "MW", label: "Malawi" },
        { value: "MY", label: "Malaysia" },
        { value: "MV", label: "Maldives" },
        { value: "ML", label: "Mali" },
        { value: "MT", label: "Malta" },
        { value: "MH", label: "Marshall Islands" },
        { value: "MQ", label: "Martinique" },
        { value: "MR", label: "Mauritania" },
        { value: "MU", label: "Mauritius" },
        { value: "YT", label: "Mayotte" },
        { value: "MX", label: "Mexico" },
        { value: "FM", label: "Micronesia, Federated States of" },
        { value: "MD", label: "Moldova, Republic of" },
        { value: "MC", label: "Monaco" },
        { value: "MN", label: "Mongolia" },
        { value: "ME", label: "Montenegro" },
        { value: "MS", label: "Montserrat" },
        { value: "MA", label: "Morocco" },
        { value: "MZ", label: "Mozambique" },
        { value: "MM", label: "Myanmar" },
        { value: "NA", label: "Namibia" },
        { value: "NR", label: "Nauru" },
        { value: "NP", label: "Nepal" },
        { value: "NL", label: "Netherlands" },
        { value: "NC", label: "New Caledonia" },
        { value: "NZ", label: "New Zealand" },
        { value: "NI", label: "Nicaragua" },
        { value: "NE", label: "Niger" },
        { value: "NG", label: "Nigeria" },
        { value: "NU", label: "Niue" },
        { value: "NF", label: "Norfolk Island" },
        { value: "MP", label: "Northern Mariana Islands" },
        { value: "NO", label: "Norway" },
        { value: "OM", label: "Oman" },
        { value: "PK", label: "Pakistan" },
        { value: "PW", label: "Palau" },
        { value: "PS", label: "Palestine, State of" },
        { value: "PA", label: "Panama" },
        { value: "PG", label: "Papua New Guinea" },
        { value: "PY", label: "Paraguay" },
        { value: "PE", label: "Peru" },
        { value: "PH", label: "Philippines" },
        { value: "PN", label: "Pitcairn" },
        { value: "PL", label: "Poland" },
        { value: "PT", label: "Portugal" },
        { value: "PR", label: "Puerto Rico" },
        { value: "QA", label: "Qatar" },
        { value: "RE", label: "Réunion" },
        { value: "RO", label: "Romania" },
        { value: "RU", label: "Russian Federation" },
        { value: "RW", label: "Rwanda" },
        { value: "BL", label: "Saint Barthélemy" },
        { value: "SH", label: "Saint Helena, Ascension and Tristan da Cunha" },
        { value: "KN", label: "Saint Kitts and Nevis" },
        { value: "LC", label: "Saint Lucia" },
        { value: "MF", label: "Saint Martin (French part)" },
        { value: "PM", label: "Saint Pierre and Miquelon" },
        { value: "VC", label: "Saint Vincent and the Grenadines" },
        { value: "WS", label: "Samoa" },
        { value: "SM", label: "San Marino" },
        { value: "ST", label: "Sao Tome and Principe" },
        { value: "SA", label: "Saudi Arabia" },
        { value: "SN", label: "Senegal" },
        { value: "RS", label: "Serbia" },
        { value: "SC", label: "Seychelles" },
        { value: "SL", label: "Sierra Leone" },
        { value: "SG", label: "Singapore" },
        { value: "SX", label: "Sint Maarten (Dutch part)" },
        { value: "SK", label: "Slovakia" },
        { value: "SI", label: "Slovenia" },
        { value: "SB", label: "Solomon Islands" },
        { value: "SO", label: "Somalia" },
        { value: "ZA", label: "South Africa" },
        { value: "GS", label: "South Georgia and the South Sandwich Islands" },
        { value: "SS", label: "South Sudan" },
        { value: "ES", label: "Spain" },
        { value: "LK", label: "Sri Lanka" },
        { value: "SD", label: "Sudan" },
        { value: "SR", label: "Suriname" },
        { value: "SJ", label: "Svalbard and Jan Mayen" },
        { value: "SZ", label: "Swaziland" },
        { value: "SE", label: "Sweden" },
        { value: "CH", label: "Switzerland" },
        { value: "SY", label: "Syrian Arab Republic" },
        { value: "TW", label: "Taiwan, Province of China" },
        { value: "TJ", label: "Tajikistan" },
        { value: "TZ", label: "Tanzania, United Republic of" },
        { value: "TH", label: "Thailand" },
        { value: "TL", label: "Timor-Leste" },
        { value: "TG", label: "Togo" },
        { value: "TK", label: "Tokelau" },
        { value: "TO", label: "Tonga" },
        { value: "TT", label: "Trinidad and Tobago" },
        { value: "TN", label: "Tunisia" },
        { value: "TR", label: "Turkey" },
        { value: "TM", label: "Turkmenistan" },
        { value: "TC", label: "Turks and Caicos Islands" },
        { value: "TV", label: "Tuvalu" },
        { value: "UG", label: "Uganda" },
        { value: "UA", label: "Ukraine" },
        { value: "AE", label: "United Arab Emirates" },
        { value: "GB", label: "United Kingdom" },
        { value: "US", label: "United States" },
        { value: "UM", label: "United States Minor Outlying Islands" },
        { value: "UY", label: "Uruguay" },
        { value: "UZ", label: "Uzbekistan" },
        { value: "VU", label: "Vanuatu" },
        { value: "VE", label: "Venezuela, Bolivarian Republic of" },
        { value: "VN", label: "Viet Nam" },
        { value: "VG", label: "Virgin Islands, British" },
        { value: "VI", label: "Virgin Islands, U.S." },
        { value: "WF", label: "Wallis and Futuna" },
        { value: "EH", label: "Western Sahara" },
        { value: "YE", label: "Yemen" },
        { value: "ZM", label: "Zambia" },
        { value: "ZW", label: "Zimbabwe" },
      ],
      options: {
        images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: 'jpg,svg,webp,gif,png',
        images_upload_handler: async  (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
          formData.append("lending_settings_id", this.$store.state.landing.settings_id);
          formData.append("image", _file);
          formData.append("file", _file);
          const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
          success(data);
        },
        plugins:
            "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",
        mobile: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
          images_file_types: 'jpg,svg,webp,gif,png',
          images_upload_handler: async  (blobInfo, success, failure) => {
            let formData = new FormData();
            let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
            formData.append("lending_settings_id", this.$store.state.landing.settings_id);
            formData.append("image", _file);
            formData.append("file", _file);
            const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
            success(data);
          },
          plugins:
              "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
        },
        menu: {
          tc: {
            title: "Comments",
            items: "addcomment showcomments deleteallconversations",
          },
        },
        menubar: "file edit view insert format tools table tc help",
        toolbar:
            "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
        autosave_ask_before_unload: true,min_height:200,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        link_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_class_list: [
          { title: "None", value: "" },
          { title: "Some class", value: "class-name" },
        ],
        importcss_append: true,
        templates: [
          {
            title: "New Table",
            description: "creates a new table",
            content:
                '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
          },
          {
            title: "Starting my story",
            description: "A cure for writers block",
            content: "Once upon a time...",
          },
          {
            title: "New list with dates",
            description: "New List with dates",
            content:
                '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
          },
        ],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: 200,
        image_caption: true,
        quickbars_selection_toolbar:
            "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        content_style: ".mymention{ color: gray; }",
        contextmenu: "link image imagetools table configurepermanentpen",
        skin: "oxide",
        content_css: ["/custom_tinymce.css"],
      },
      selectedSegments:[],
      allDates:false,
      allTimes:true,
      successMessage:"",
      selected_lang:0,
      dropzoneOptions: {
        url: () => {
          return process.env.VUE_APP_API_URL + "image-upload";
        },
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        maxFilesize: 3,
        maxFiles: 1,
        destroyDropzone: false,
        dictFileTooBig:"File is too big ({{filesize}}mb). Max file size: 3mb",
        autoProcessQueue: false,
        thumbnail: (file, dataUrl) => {
          if (file.cropped) {
            return;
          }else{
            this.compress(file)
          }
        },
      },
      preview:false,
      labelIconColorHover:false,
      labelIconColor:false,
      selectedLangs:['en'],
      selectedLang:''
    }
  },
  async created(){
    this.templates = await liteLabelsService.getLabelTemplates();
    if(this.templates.length){
      if(this.type === 'Create'){
        this.selectedTemplate = this.templates[0];
        this.label.bottom_text = this.templates[0].bottom_text
        this.label.header_text = this.templates[0].header_text
      }
    }else{
      if(this.type === 'Create') {
        this.label.qr = this.qrLink;
      }
    }
  },
  computed:{
    ...mapState(['landing']),
    ...mapState('landing',['selected_langs']),
    ...mapState('rooms',['roomCategories']),
    ...mapState('segments',['segments']),
    qrLink(){
      return 'https://guest.eco/'+this.objectItem.id;
    },
    styleObjectPlay() {
      return {
        "--color":
        this.label.styles.banner_video_icon_color,
        "--color-hover": this.label.styles.banner_video_icon_color_hover,
        width: this.label.styles.banner_video_icon_size + "px",
        height: this.label.styles.banner_video_icon_size + "px",
        left: `calc(50% - ${this.label.styles.banner_video_icon_size / 2}px)`,
        top: `calc(50% - ${this.label.styles.banner_video_icon_size / 2}px)`,
      };
    },
  },
  components:{
    PopupSuccess:() => import("@/components/Popup/PopupSuccess")
  },
  mounted(){
    this.forceRerender();
    this.rerenderQr();
  },
  methods:{
    ...mapActions('banners',['createBanner','editBanner']),
    ...mapActions('segments',['getSegments']),
    closePreviewModal(){
      this.preview_modal = false;
    },
    async openMenuBottom(menu){
      if(menu.id === 0){
        this.preview_modal = true;
      }
      if(menu.id === 2){
        await this.download();
      }
      if(menu.id === 1){
        this.save();
      }
    },
    async download(){
      const wrapper = this.$refs[`ref_${this.label.qr}`];
      console.log(this.$refs);
      const img = wrapper.getElementsByTagName('canvas')[0].toDataURL("image/png");
      const html = {
        html:
            `${wrapper.innerHTML}`
      };
      const updateSize = this.$store.state.isMobile?html.html.replaceAll('width: 100%','width: 700px').replace('564','987'):html.html.replace('400','700').replace('564','987');
      const updateImage = updateSize.indexOf('src')===-1?updateSize.replace('alt="Scan me!"','alt="Scan me!" src="'+img+'"').replace('none !important;','block !important;width:'+this.label.styles.qr.size+'px;height:'+this.label.styles.qr.size+'px;'):updateSize;
      const sendHtml = {
        html:updateImage,
        hotel_id: this.objectItem.id,
        sticker_id: this.label.id,
      }
      const data = await HotelService.getHtmlLink(sendHtml)
      const sendPdf = {
        html:data,
        hotel_id: this.objectItem.id,
        sticker_id: this.label.id,
      }
      const pdf = await HotelService.getPdfLinkFromHtmlLink(sendPdf);
      const link = document.createElement("a");
      link.download = `${this.objectItem.name}_${this.$moment().format('DD.MM.YYYY HH:mm')}.pdf`;
      link.href = pdf
      link.target = "_blank"
      const evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      link.dispatchEvent(evt);
      link.remove()
    },
    async print(label){
      const html = `
        <html>
          <head>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
          <style>
          @font-face {
            font-family: 'Impact';
            src: url(https://api.guesteco.com/storage/fonts/Impact/Impact.ttf) format('ttf');
          }
          </style>
          </head>
          <body>${this.$refs[`ref_${label.qr}`].innerHTML}</body>
      </html>
    `;
      const updateSize = html.replace('400','700').replace('564','987');
      this.printElement(updateSize);
    },
    printElement(elem)
    {
      let printWindow = window.open('', 'PRINT', 'height=400,width=600');
      printWindow.document.write(elem);
      printWindow.document.close(); // necessary for IE >= 10
      printWindow.focus(); // necessary for IE >= 10*/
      printWindow.print();
      printWindow.close();
      return true;
    },
    async rerenderQr(){
      this.renderQR = false;
      await this.$nextTick();
      this.renderQR = true;
    },
    async forceRerender() {
      this.renderComponent = false;
      await this.$nextTick();
      this.renderComponent = true;
    },
    changeFormatLangPic(lang) {
      const langArr = lang.split("-");
      if (langArr[1]) {
        const candidate = this.countries.find(
            (x) => x.value === langArr[1].toUpperCase()
        );
        if (candidate) {
          return candidate.value;
        }
      } else {
        if(lang === 'en'){
          return 'GB';
        }
        return lang.toUpperCase();
      }
    },
    changeAllWeek(){
      if(this.allTimes){
        this.label.show_all_week = 1;
      }else{
        this.label.show_all_week = 0;
      }
    },
    changeLang(lang){
      this.landing.current_lang = lang;
      this.mountDropzone()
    },
    setShowAllTime(){
      if(this.allDates){
        this.label.show_all_time = 1;
      }else{
        this.label.show_all_time = 0;
      }
    },
    async save(){
      if(this.type === 'Edit'){
        const newLabel = await liteLabelsService.putLabel(this.label);
        this.successMessage = "You label edit successfully!";
        this.$emit('edit',newLabel);
        this.$refs.PopupSuccess.open()
      }
    },
    closeLabelDialog(){
      this.$emit('closeLabelDialog');
    },
    openLinkAddBanner(link){
      if(link){
        window.open(link,'_blank')
      }
    },
    async onFileChange(file){
      try{
        if(file){
          let formData = new FormData();
          formData.append('lending_settings_id',this.landing.settings_id);
          formData.append('image',file);
          formData.append('file',file);
          const response = await this.$axios.post('upload-image',formData);
          this.label.video[this.landing.current_lang] = response.data;
        }
      }catch(e){
        console.log(e);
      }
    },
    compress(file){
      new Compressor(file, {
        quality: 0.6,
        mimeType:'image/webp',
        convertSize:0,
        convertTypes:['image/jpeg','image/png','image/jpg'],
        success:(result) => {
          if(this.$store.state.isMobile){
            this.saveImg(result)
          }else{
            this.$refs.PopupCrop.open(result)
          }
        },
        error(e) {
          console.log(e);
        }
      })
    },
    blobToDataURL(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = _e => resolve(reader.result);
        reader.onerror = _e => reject(reader.error);
        reader.onabort = _e => reject(new Error("Read aborted"));
        reader.readAsDataURL(blob);
      });
    },
    dataURItoBlob(dataURI) {
      let byteString = atob(dataURI.split(",")[1]);
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    async saveImg(file){
      file.cropped = true;
      this.$refs.upload.dropzone.removeAllFiles();
      this.$refs.upload.dropzone.addFile(file);
      this.$refs.upload.dropzone.processQueue();
      this.$refs.upload.dropzone[
          "previewsContainer"
          ].children[1].children[0].children[0].src = await this.blobToDataURL(file);
      this.$refs.upload.dropzone[
          "previewsContainer"
          ].children[1].children[0].style.borderRadius = 0;
      this.$refs.upload.dropzone[
          "previewsContainer"
          ].children[1].children[1].style.background = "none";
    },
    openEditor(file){
      let editor = document.createElement("div");
      editor.style.position = "fixed";
      editor.style.left = 0;
      editor.style.right = 0;
      editor.style.top = 0;
      editor.style.bottom = 0;
      editor.style.width = "50%";
      editor.style.zIndex = 9999;
      editor.style.backgroundColor = "#000";
      document.body.appendChild(editor);
      if (this.label.logo) {
        this.$refs.upload.dropzone.removeFile(file);
      }
      let buttonConfirm = document.createElement("button");
      buttonConfirm.style.position = "absolute";
      buttonConfirm.style.right = "10px";
      buttonConfirm.style.top = "10px";
      buttonConfirm.style.zIndex = 9999;
      buttonConfirm.textContent = "Save image";
      buttonConfirm.className =
          "v-btn v-size--small confirm-control";
      editor.appendChild(buttonConfirm);
      let buttonCancel = document.createElement("button");
      buttonCancel.style.position = "absolute";
      buttonCancel.style.right = "135px";
      buttonCancel.style.top = "10px";
      buttonCancel.style.zIndex = 9999;
      buttonCancel.textContent = "Cancel";
      buttonCancel.className =
          "v-btn v-size--small confirm-control";
      editor.appendChild(buttonCancel);
      buttonCancel.addEventListener("click", () => {
        this.$refs.upload.dropzone.removeAllFiles();
        document.body.removeChild(editor);
        cropper.destroy();
      });
      let buttonRotateLeft = document.createElement("button");
      buttonRotateLeft.className = "cropper-controls";
      buttonRotateLeft.style.left = "24px";
      buttonRotateLeft.innerHTML =
          `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M13,4.07V1L8.45,5.55L13,10V6.09C15.84,6.57 18,9.03 18,12C18,14.97 15.84,17.43 13,17.91V19.93C16.95,19.44 20,16.08 20,12C20,7.92 16.95,4.56 13,4.07M7.1,18.32C8.26,19.22 9.61,19.76 11,19.93V17.9C10.13,17.75 9.29,17.41 8.54,16.87L7.1,18.32M6.09,13H4.07C4.24,14.39 4.79,15.73 5.69,16.89L7.1,15.47C6.58,14.72 6.23,13.88 6.09,13M7.11,8.53L5.7,7.11C4.8,8.27 4.24,9.61 4.07,11H6.09C6.23,10.13 6.58,9.28 7.11,8.53Z" />
</svg>`;
      editor.appendChild(buttonRotateLeft);
      let buttonRotateRight = document.createElement("button");
      buttonRotateRight.className = "cropper-controls";
      buttonRotateRight.style.left = "64px";
      buttonRotateRight.innerHTML =
          `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M16.89,15.5L18.31,16.89C19.21,15.73 19.76,14.39 19.93,13H17.91C17.77,13.87 17.43,14.72 16.89,15.5M13,17.9V19.92C14.39,19.75 15.74,19.21 16.9,18.31L15.46,16.87C14.71,17.41 13.87,17.76 13,17.9M19.93,11C19.76,9.61 19.21,8.27 18.31,7.11L16.89,8.53C17.43,9.28 17.77,10.13 17.91,11M15.55,5.55L11,1V4.07C7.06,4.56 4,7.92 4,12C4,16.08 7.05,19.44 11,19.93V17.91C8.16,17.43 6,14.97 6,12C6,9.03 8.16,6.57 11,6.09V10L15.55,5.55Z" />
</svg>`;
      editor.appendChild(buttonRotateRight);
      let buttonCrop = document.createElement("button");
      buttonCrop.className = "cropper-controls active-control";
      buttonCrop.style.left = "104px";
      buttonCrop.innerHTML =
          `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M7,17V1H5V5H1V7H5V17A2,2 0 0,0 7,19H17V23H19V19H23V17M17,15H19V7C19,5.89 18.1,5 17,5H9V7H17V15Z" />
</svg>`;
      editor.appendChild(buttonCrop);
      let buttonZoomPlus = document.createElement("button");
      buttonZoomPlus.className = "cropper-controls";
      buttonZoomPlus.style.left = "144px";
      buttonZoomPlus.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14L20.5,19L19,20.5L14,15.5V14.71L13.73,14.43C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.43,13.73L14.71,14H15.5M9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14M12,10H10V12H9V10H7V9H9V7H10V9H12V10Z" />
</svg>`;
      editor.appendChild(buttonZoomPlus);
      let buttonZoomMinus = document.createElement("button");
      buttonZoomMinus.className = "cropper-controls";
      buttonZoomMinus.style.left = "184px";
      buttonZoomMinus.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14H14.71L14.43,13.73C15.41,12.59 16,11.11 16,9.5A6.5,6.5 0 0,0 9.5,3A6.5,6.5 0 0,0 3,9.5A6.5,6.5 0 0,0 9.5,16C11.11,16 12.59,15.41 13.73,14.43L14,14.71V15.5L19,20.5L20.5,19L15.5,14M9.5,14C7,14 5,12 5,9.5C5,7 7,5 9.5,5C12,5 14,7 14,9.5C14,12 12,14 9.5,14M7,9H12V10H7V9Z" />
</svg>`;
      editor.appendChild(buttonZoomMinus);
      let buttonLeftRight = document.createElement("button");
      buttonLeftRight.className = "cropper-controls";
      buttonLeftRight.style.left = "224px";
      buttonLeftRight.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M6.45,17.45L1,12L6.45,6.55L7.86,7.96L4.83,11H19.17L16.14,7.96L17.55,6.55L23,12L17.55,17.45L16.14,16.04L19.17,13H4.83L7.86,16.04L6.45,17.45Z" />
</svg>`;
      editor.appendChild(buttonLeftRight);
      let buttonUpDown = document.createElement("button");
      buttonUpDown.className = "cropper-controls";
      buttonUpDown.style.left = "264px";
      buttonUpDown.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M17.45,17.55L12,23L6.55,17.55L7.96,16.14L11,19.17V4.83L7.96,7.86L6.55,6.45L12,1L17.45,6.45L16.04,7.86L13,4.83V19.17L16.04,16.14L17.45,17.55Z" />
</svg>`;
      editor.appendChild(buttonUpDown);
      buttonConfirm.addEventListener("click", () => {
        let canvas = cropper.getCroppedCanvas();
        let newFile = this.dataURItoBlob(canvas.toDataURL(file.type));
        newFile.cropped = true;
        newFile.name = file.name;
        this.$refs.upload.dropzone.removeAllFiles();
        this.$refs.upload.dropzone.addFile(newFile);
        this.$refs.upload.dropzone.processQueue();
        this.$refs.upload.dropzone[
            "previewsContainer"
            ].children[1].children[0].children[0].src =  canvas.toDataURL(file.type);
        this.$refs.upload.dropzone[
            "previewsContainer"
            ].children[1].children[0].style.borderRadius = 0;
        this.$refs.upload.dropzone[
            "previewsContainer"
            ].children[1].children[1].style.background = "none";
        this.preview = false;
        document.body.removeChild(editor);
        cropper.destroy();
      });
      buttonRotateRight.addEventListener("click", () => {
        if (!buttonRotateRight.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonRotateRight.classList.add("active-control");
        }
        cropper.rotate(45);
      });
      buttonRotateLeft.addEventListener("click", () => {
        if (!buttonRotateLeft.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonRotateLeft.classList.add("active-control");
        }
        cropper.rotate(-45);
      });

      buttonCrop.addEventListener("click", () => {
        if (!buttonCrop.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonCrop.classList.add("active-control");
        }
        cropper.setDragMode("crop");
      });
      buttonZoomPlus.addEventListener("click", () => {
        if (!buttonZoomPlus.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonZoomPlus.classList.add("active-control");
        }
        cropper.zoom(0.1);
      });
      buttonZoomMinus.addEventListener("click", () => {
        if (!buttonZoomMinus.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonZoomMinus.classList.add("active-control");
        }
        cropper.zoom(-0.1);
      });
      buttonLeftRight.addEventListener("click", () => {
        if (!buttonLeftRight.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonLeftRight.classList.add("active-control");
        }
        if (cropper.getData().scaleX === 1) {
          cropper.scaleX(-1);
        } else {
          cropper.scaleX(1);
        }
      });
      buttonUpDown.addEventListener("click", () => {
        if (!buttonUpDown.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonUpDown.classList.add("active-control");
        }
        if (cropper.getData().scaleY === 1) {
          cropper.scaleY(-1);
        } else {
          cropper.scaleY(1);
        }
      });
      let image = new Image();
      image.src = URL.createObjectURL(file);
      editor.appendChild(image);
      this.preview = true;
      let cropper = new Cropper(image, {
        preview: ".preview",
        aspectRatio: ((360/100)*+this.label.styles.width) / ((570/100)*+this.label.styles.height),
      });
    },
    closeCrop(){
      this.$refs.upload.dropzone.removeAllFiles();
    },
    customEvent(_file, xhr,formData){
      xhr.setRequestHeader(
          "Authorization",
          `Bearer ${this.$store.state.token}`
      );
      formData.append('file',_file);
    },
    handleRemove(){
      this.label.logo = "";
    },
    handleSuccess(file){
      this.label.logo = file.xhr.response;
    },
    deletePeriod(times,index){
      times.time_start.splice(index,1);
      times.time_end.splice(index,1);
    },
    addPeriod(day){
      const defaultItemTimeStart = {
        HH:"00",
        mm:"00"
      }
      const defaultItemTimeEnd = {
        HH:"23",
        mm:"59"
      }
      day.time_start.push(defaultItemTimeStart);
      day.time_end.push(defaultItemTimeEnd);
    },
    indexArr(times){
      return times.time_start.reduce((acc,item,index) => {
        acc.push(index)
        return acc
      },[])
    },
    changeDates() {
      if (this.label.dates[0] && this.label.dates[1]) {
        if (this.label.dates[0] > this.label.dates[1]) {
          let start = this.label.dates[0];
          let end = this.label.dates[1];
          this.label.dates[0] = end;
          this.label.dates[1] = start;
        }
      }
      this.dateRangeText();
    },
    dateRangeText() {
      let date_start = null;
      let date_end = null;
      if (this.label.dates[0] && this.label.dates[1]) {
        date_start = this.$moment(this.label.dates[0]).format("DD.MM.YYYY");
        date_end = this.$moment(this.label.dates[1]).format("DD.MM.YYYY");
      } else if (this.label.dates[0]) {
        date_start = this.$moment(this.label.dates[0]).format("DD.MM.YYYY");
      } else if (this.label.dates[1]) {
        date_end = this.$moment(this.label.dates[1]).format("DD.MM.YYYY");
      } else {
        this.label.date_model = "";
      }
      this.label.date_from = date_start;
      this.label.date_to = date_end;
      this.label.date_model = [date_start, date_end].join(" - ");
    },
    mountDropzone(){
      let elements =
          this.$refs.upload.$el.getElementsByClassName("dz-preview");
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
      if(this.label.logo){
        let mockFile = {
          accepted: true,
          cropped:true,
          kind: "image",
        };
        this.$refs.upload.manuallyAddFile(mockFile, this.label.logo);
        this.$refs.upload.dropzone.emit("thumbnail", mockFile, this.label.logo);
        this.$refs.upload.dropzone.emit("complete", mockFile);
        this.$refs.upload.dropzone['previewsContainer'].children[1].children[0].children[0].src = this.label.logo;
        this.$refs.upload.dropzone['previewsContainer'].children[1].children[0].style.borderRadius = 0;
        this.$refs.upload.dropzone['previewsContainer'].children[1].children[1].style.background = 'none';
      }
    },
  }
}
</script>

<style lang="scss">
.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  background-color: #2196F3;
  padding: 10px;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 20px;
  font-size: 30px;
  text-align: center;
}
.color{
  cursor: pointer;
  height: 30px;
  width: 30px;
  border: 1px solid #000;
  border-radius: 4px;
}
.vue__time-picker .dropdown ul li:not([disabled]).active {
  background: steelblue;
}
.play-video{
  cursor: pointer;
  -webkit-mask-image: url(~@/assets/icons/play_button.svg);
  mask-image: url(~@/assets/icons/play_button.svg);
  background-color:var(--color);
  position: absolute;
  &:hover{
    background-color:var(--color-hover);
  }
  &_with_img{
    position: absolute;
    top:150px;
    left:calc(50% - 40px);
  }
}
.dropzone .dz-preview:hover .dz-details{
  opacity: 0;
}
.canvas-menu {
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 5px;
}
.canvas-menu-drag {
  display: flex;
  flex-wrap: wrap;
}

.canvas-menu_tile {
  flex-wrap: wrap;
  .canvas-menu__item {
    width: 50%;
    margin-bottom: 0;
    padding: 0 5px 10px;
  }
  .canvas-menu__link {
    flex-direction: column;
  }
  .canvas-menu__icon {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.canvas-menu-custom {
  width: 100%;
  & .canvas-menu__item {
    margin-bottom: 0;
    padding: 0 5px 10px;
  }
  & .canvas-menu__link {
    background-repeat: no-repeat;
    background-size: cover;
  }
  .canvas-menu__icon {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.canvas-menu__item {
  margin-bottom: 10px;
}

.canvas-menu__link {
  display: flex;
  align-items: center;
  text-align: center;
  height: 100%;
  text-decoration: none;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  color: inherit;
}

.canvas-menu__icon {
  width: 36px;
  height: 36px;
  margin-right: 10px;
  &.v-icon {
    // margin-bottom: 10px;
    margin-right: 10px;
    font-size: 36px;
  }
}

.canvas-menu__text {
  color: inherit;
  text-align: center;
}

.dropzone .dz-preview .dz-details .dz-size {
  display: none;
}
.dropzone .dz-preview .dz-details .dz-filename {
  display: none;
}
.vue-dropzone > .dz-preview .dz-image {
  max-width: 150px;
  max-height: 150px;
  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
}.cropper-bg {
   background-repeat: repeat;
 }
.cropper-controls {
  position: absolute;
  top: 10px;
  height: 36px;
  width: 36px;
  z-index: 9999;
  border-radius: 5px;
  background: #fff;
  border: 2px solid rgb(0, 125, 181);
  display: flex;
  justify-content: center;
  align-items: center;
}
.cropper-controls::before {
  background-color: #007aff;
  border-radius: inherit;
  bottom: 0;
  color: inherit;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}
.confirm-control {
  font-family: Inter, sans-serif;
  background: #fff;
  border: 2px solid rgb(0, 125, 181);
  color: #007aff;
  border-radius: 5px;
}
.confirm-control::before {
  background-color: #007aff;
  border-radius: inherit;
  bottom: 0;
  color: inherit;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}
.confirm-control:hover::before {
  opacity: 0.2;
}
.active-control::before {
  opacity: 0.2;
}
.dropzone .dz-preview .dz-details .dz-size {
  display: none;
}
.dropzone .dz-preview .dz-details .dz-filename {
  display: none;
}
.dropzone {
  & .dz-preview {
    & .dz-image {
      & img {
        width: 150px;
        height: 150px;
        object-fit: contain;
      }
    }
    & .dz-error-message{
      width:200px;
      top:90px;
    }
    & .dz-remove{
      margin-left: 9px;
      padding: 5px 10px;
      bottom: 8px;
    }
  }
}

.custom__menu--color {
  cursor: pointer;
  height: 30px;
  width: 30px;
  border-radius: 4px;
  border: 1px solid #000;
}
</style>
