import $axios from "@/plugins/axios";

const LabelService = {
  async getLabels(hotel_id){
    try{
      const {data} = await $axios.get(`owner-labels/${hotel_id}`)
      return data;
    }catch (e){
      return e;
    }
  },
  async getLabel(id){
    try{
      const {data} = await $axios.get(`owner-label/${id}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async postLabel(label){
    try{
      const {data} = await $axios.post(`owner-label`,label);
      return data;
    }catch (e) {
      return e;
    }
  },
  async putLabel(label){
    try{
      const {data} = await $axios.put(`owner-label/${label.id}`,label);
      return data;
    }catch (e) {
      return e;
    }
  },
  async deleteLabel(id){
    try{
      const {data} = await $axios.delete(`owner-label/${id}`);
      return data;
    }catch (e){
      return e;
    }
  },
}
export default LabelService
